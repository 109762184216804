@font-face {
  font-family: 'Montserrat-black';
  src: url('fonts/Montserrat-Black.ttf');
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: 'Montserrat-bold';
  src: url('fonts/Montserrat-Bold.ttf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-medium';
  src: url('fonts/Montserrat-Medium.ttf');
  font-display: swap;
}