.animate {
  animation: hlines 5s infinite;
}

@keyframes hlines {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}