.answer-item {
  &::before {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 9999px;
    aspect-ratio: 1/1;
    color: #898EA7;
    background-color: #363948;
    margin-right: 20px;
    width: 7%;
    content: 'A';
  }

  &:nth-of-type(2):before {
    content: 'B';
  }

  &:nth-of-type(3):before {
    content: 'C';
  }

}












