.pulse {
  animation: pulse 3s infinite;

  &__shadow {
    animation: pulse-shadow 3s infinite;
  }
}

@keyframes pulse {
  0%, 100%{
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(254, 205, 0, 0.7);
  }

  50% {
    box-shadow: 0 0 0 1.2vw rgba(254, 205, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(254, 205, 0, 0);
  }
}