.box-top {
  max-width: 360px;
  transition: all 2s;
  z-index: 20;

  &.preview {
    transform: translateY(-100px);

  }
}

.open {
  animation: box-open 4s ease-out;
  transform: translate(75%, 200px) rotate(45deg);
  z-index: 50;
}

.closed {
  transform: translate(0, 100px);
}


@keyframes box-open {
  0% {
    transform: translate(0, 100px) rotate(0deg)
  }
  40%, 60% {
    transform: translate(0, -100px) rotate(0deg);
  }
  100% {
    transform: translate(75%, 200px) rotate(45deg);
  }
}

.smiley {
  max-width: 250px;
  transition-duration: 2s;

  &.preview {
    transform: translateY(-170px);
    transition-delay: 0.5s;

  }
}

.animate {
  transform: translateY(-100px);
  transition: all;
  transition-delay: 3.5s;
  transition-duration: 2s;
}

.light {
  opacity: 1;
}

.box {
  &.move-box-A {
    animation: ease-in-out moveBoxA 10s 10s;
  }

  &.move-box-B {
    animation: ease-in-out moveBoxB 10s 10s;
  }

  &.move-box-C {
    animation: ease-in-out moveBoxC 10s 10s;
  }

  &.fail-animation {
    .box-top {
      transform: translateY(-80px);
    }

    .smiley {
      transform: translateY(-100px);
    }
  }
}

@keyframes moveBoxA {
  10%, 30%, 60%, 90% {
    transform: translateX(calc(720px + 280px));
  }
  20%, 50%, 70% {
    transform: translateX(calc(360px + 160px));
  }
  40%, 80%, 100% {
    transform: translateX(0%);
  }
}

@keyframes moveBoxB {
  10%, 50%, 60%, 90% {
    transform: translateX(calc(-360px - 155px));
  }
  30%, 60%, 70%, 100% {
    transform: translateX(0%);
  }
  20%, 40%, 80% {
    transform: translateX(calc(360px + 155px));
  }
}

@keyframes moveBoxC {
  10%, 40%, 70%, 80%, 90% {
    transform: translateX(calc(-360px - 155px));
  }
  50%, 100% {
    transform: translateX(calc(0%));
  }
  20%, 30%, 60% {
    transform: translateX(calc(-770px - 260px));
  }
}
