.fadeIn{
  animation-name:fadeIn;
  animation-duration:1s;
  position:unset;
  animation-fill-mode:backwards;

  &--delay1{
  animation-delay:.15s;
  }

  &--delay2{
  animation-delay:.3s;
  }

  &--delay3{
  animation-delay:.45s;
  }

  &--delay4{
  animation-delay:.6s;
  }

  &--delay5{
  animation-delay:.75s;
  }

  &--delay6{
  animation-delay:1s;
  }

  &--delay7{
  animation-delay:1.15s;
  }

  &--delay8{
  animation-delay:1.3s;
  }
  }

  @keyframesfadeIn{
  from{
  opacity:0;
  position:relative;
  top:50vh;
  }
  to{
  opacity:1;
  top:0px;
  }
}
