@import "./src/scss/abstracts/variables";

.backdrop {

  &__shape {
    z-index: 0;
    top: 0%;
    right: 0%;
    width: 60vw;
    height: 60vw;
    transform: rotate(45deg);
  }
}
