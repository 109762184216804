.animate-big {
  animation:big 15s infinite;
  transition: all .25s;
}.animate-small {
  animation: small 20s infinite ;
   transition: all .25s;
}

 @keyframes big {
   0%, 100% {
     transform: scale(1) translate(0,0);
   }
   50% {
     transform: scale(1.05) translate(5%, -5%);
   }
   25%, 75% {
     transform: scale(.95) translate(-5%, 5%);
   }
 }@keyframes small {
   0%, 100% {
     transform: scale(1) translate(0,0);
   }
   50% {
     transform: scale(1.05) translate(15%, 5%);
   }
   25%, 75% {
     transform: scale(.95) translate(-5%, -5%);
   }
 }