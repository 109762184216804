.fadeIn {
  opacity: 0;
  animation: fadeIn 5s forwards;
  animation-delay: 1.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}