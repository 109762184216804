.flip-card {

  &-inner {
    animation-name: flip;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-play-state: running;
    transform-style: preserve-3d;
    min-height: max-content;
  }

  &-front, &-back, &-quest, &-ans {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &-front, &-ans {
    transform: rotateY(180deg);
  }

  &:nth-child(1) &-inner {
    animation-delay: 1s;
  }

  &:nth-child(2) &-inner {
    animation-delay: 2s;
  }

  &:nth-child(3) &-inner {
    animation-delay: 3s;
  }
}

@keyframes flip {
  from {transform: rotateY(0deg);}
  to {transform: rotateY(180deg);}
}
