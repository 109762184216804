.circle-top {
  animation: circle-top 10s infinite;
}

@keyframes circle-top {
  0%,100% {
    transform: scale(1) translate(0,0);
  }
  50% {
    transform: scale(1.05) translate(-10%,10%);
  }
}

.circle-bottom {
  animation: circle-bottom 5s infinite;
}

@keyframes circle-bottom {
  0%,100% {
    transform: scale(1.05) translate(10%,-10%);
  }
  50% {
    transform: scale(1) translate(0,0);
  }
}